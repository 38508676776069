/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import css from './ListingPage.module.css';

import { Button, SecondaryButton } from '../../components';
import Spacer, { HORIZONTALL_AXIS } from '../../components/Spacer/Spacer';

import LikeBlankIcon from '../../assets/icons/hearts/blank-24.png';
import LikeFilledIcon from '../../assets/icons/hearts/filled-24.png';
import config from '../../config';

import ApplePayIcon from '../../assets/icons/payment-methods/apple-pay.svg';
import GooglePayIcon from '../../assets/icons/payment-methods/google-pay.svg';
import MastercardIcon from '../../assets/icons/payment-methods/mastercard.svg';
import VisaIcon from '../../assets/icons/payment-methods/visa.svg';
import MaestroIcon from '../../assets/icons/payment-methods/maestro.svg';
import PayPalIcon from '../../assets/icons/payment-methods/pay-pal.svg';
import AmexIcon from '../../assets/icons/payment-methods/amex.svg';

import ShippingIcon from '../../assets/icons/buyer-protection/shipping.png';
import SecurePaymentsIcon from '../../assets/icons/buyer-protection/secure-payments.png';
import VerifiedSellersIcon from '../../assets/icons/buyer-protection/verified-sellers.png';
import ReturnsIcon from '../../assets/icons/buyer-protection/returns.png';

function SectionHeading({
  priceTitle,
  formattedPrice,
  richTitle,
  isOwnListing,
  onBuyNow,
  buyLoading,
  intl,
  listing,
  addUserFavorite,
  removeUserFavorite,
  isLiked,
  listingId,
  currentUserId,
  listingType,
  availableForPurchase,
}) {
  const handleLikeClick = e => {
    e.stopPropagation();
    e.preventDefault();

    return isLiked ? removeUserFavorite(listingId) : addUserFavorite(listingId, listingType);
  };

  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = config.listingManagementType === 'stock' && currentStock === 0;

  const buyNowButton =
    !isOwnListing && availableForPurchase ? (
      <Button rootClassName={css.buyNowButton} onClick={onBuyNow} disabled={isOutOfStock} inProgress={buyLoading}>
        {intl.formatMessage({ id: 'listing_page.buy_now' })}
      </Button>
    ) : null;

  const likeButton =
    !isOwnListing && currentUserId ? (
      <Button rootClassName={css.likeListingButton} onClick={e => handleLikeClick(e)}>
        {isLiked ? (
          <img className={css.likeIcon} src={LikeFilledIcon} />
        ) : (
          <img className={css.likeIcon} src={LikeBlankIcon} />
        )}
        <Spacer axis={HORIZONTALL_AXIS} size={6} />
        {isLiked
          ? intl.formatMessage({ id: 'listing_page.remove_from_favorites' })
          : intl.formatMessage({ id: 'listing_page.add_to_favorites' })}
      </Button>
    ) : null;

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>{richTitle}</div>
      <div className={css.desktopPriceContainer}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
        </div>

        <div className={css.desktopHeadingActions}>
          {buyNowButton}
          {likeButton}
        </div>
        <div className={css.paymentMethodWrapper}>
          <img className={css.paymentMethodImage} src={ApplePayIcon} alt='Apple Pay' />
          <img className={css.paymentMethodImage} src={GooglePayIcon} alt='Google Pay' />
          <img className={css.paymentMethodImage} src={MastercardIcon} alt='Mastercard' />
          <img className={css.paymentMethodImage} src={MaestroIcon} alt='Mastercard' />
          <img className={css.paymentMethodImage} src={VisaIcon} alt='Visa' />
          <img className={css.paymentMethodImage} src={PayPalIcon} alt='Visa' />
          <img className={css.paymentMethodImage} src={AmexIcon} alt='Visa' />

        </div>
        <Spacer size={16} />
        <div className={css.buyerProtectionInfoWrapper}>
          <label className={css.buyerProtectionInfoLabel}>
            {intl.formatMessage({ id: 'listing_page.buyer_protection_info.label' })}
          </label>
          <p className={css.buyerProtectionInfoDescription}>
            {intl.formatMessage({ id: 'listing_page.buyer_protection_info.description' })}
          </p>
          <div className={css.buyerProtectionPropositionWrapper}>
            <img className={css.buyerProtectionPropositionLogo} src={ShippingIcon} />
            <p className={css.buyerProtectionPropositionText}>
              {intl.formatMessage({ id: 'listing_page.buyer_protection_info.proposition.shipping' })}
            </p>
          </div>
          <div className={css.buyerProtectionPropositionWrapper}>
            <img className={css.buyerProtectionPropositionLogo} src={SecurePaymentsIcon} />
            <p className={css.buyerProtectionPropositionText}>
              {intl.formatMessage({ id: 'listing_page.buyer_protection_info.proposition.secure_payments' })}
            </p>
          </div>
          <div className={css.buyerProtectionPropositionWrapper}>
            <img className={css.buyerProtectionPropositionLogo} src={VerifiedSellersIcon} />
            <p className={css.buyerProtectionPropositionText}>
              {intl.formatMessage({ id: 'listing_page.buyer_protection_info.proposition.verified_sellers' })}
            </p>
          </div>
          <div className={css.buyerProtectionPropositionWrapper}>
            <img className={css.buyerProtectionPropositionLogo} src={ReturnsIcon} />
            <p className={css.buyerProtectionPropositionText}>
              {intl.formatMessage({ id: 'listing_page.buyer_protection_info.proposition.returns' })}
            </p>
          </div>

        </div>
      </div>
    </div>
  );
}

export default SectionHeading;
